var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"auth__img"},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo"}})]),_c('ValidationObserver',{ref:"observer",staticClass:"auth__content",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h2',{staticClass:"title"},[_vm._v("Password Reset")]),_c('p',[_vm._v("Set up a new password")]),_c('section',{staticClass:"form"},[_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: true, verify_password: true, max: 255 },"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('PasswordInput',{class:{ 'form-control--invalid': _vm.error },attrs:{"placeholder":"Enter your new password","type":"password","name":"password","validateError":errors[0] || _vm.error,"element":_vm.password},on:{"input":function($event){_vm.password = $event}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":"required|confirmed:password|min: 8|max: 255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('PasswordInput',{class:{ 'form-control--valid': valid },attrs:{"placeholder":"Confirm your new password","type":"password","validateError":errors[0],"element":_vm.password_confirmation},on:{"input":function($event){_vm.password_confirmation = $event}},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})]}}],null,true)})],1)]),_c('BaseButton',{staticClass:"button--admin button--full-width button--green",attrs:{"text":"Save","loading":_vm.loading,"disabled":invalid},on:{"click":_vm.save}})],1)]}}])}),_c('div',{staticClass:"auth__footer",staticStyle:{"padding":"15px 40px"}},[_c('p',[_vm._v("© Collekt 2022")]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/terms-and-conditions"}},[_vm._v("Terms & Conditions")])],1),_c('li',[_c('router-link',{attrs:{"to":"/privacy-policy"}},[_vm._v("Privacy Policy")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }